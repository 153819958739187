import React from "react";

const AccordionSummary = (props) => {
  const { attributes, children, element } = props;
  const { textColor, bgColor, borderColor } = element;

  return (
    <div
      className={`accordion-summary-container`}
      {...attributes}
      style={{
        width: "100%",
        position: "relative",
        backgroundColor: bgColor,
        border: `1px solid ${borderColor}`,
        color: textColor,
      }}
    >
      {children}
    </div>
  );
};

export default AccordionSummary;
