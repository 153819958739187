import React, { useEffect, useState } from "react";
import { useSlate } from "slate-react";
import {
  Box,
  Dialog,
  IconButton,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import MENU_OPTIONS from "./Options/Options";
import TextFormat from "../PopupTool/TextFormat";
import AddElements from "../PopupTool/AddElements";
import AddTemplates from "../PopupTool/AddTemplates";
import miniToolbarStyles from "./Styles";
import usePopupStyle from "../PopupTool/PopupToolStyle";
import PopperHeader from "../PopupTool/PopperHeader";
import { useEditorContext } from "../../hooks/useMouseMove";
import PageSettingsButton from "../../Elements/PageSettings/PageSettingsButton";

const POPUP_TYPES = {
  textFormat: TextFormat,
  addElement: AddElements,
  addTemplate: AddTemplates,
};

const FULLSCREEN_POPUP = {
  addTemplate: true,
};

const POPUP_TITLE = {
  textFormat: "Text Settings",
  addElement: "Add Elements",
  addTemplate: "Templates",
};

const MiniToolbar = (props) => {
  const { customProps, theme } = props;
  const classes = miniToolbarStyles(theme);
  const popupStyles = usePopupStyle(theme);
  const editor = useSlate();
  const [popper, setPopper] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [search, setSearch] = useState("");
  const PopupComponent = POPUP_TYPES[popper] || null;
  const open = Boolean(PopupComponent);
  const DialogComp = !fullScreen ? Popper : Dialog;
  const { hideTools } = customProps;
  const UPDATED_MENU_OPTIONS = MENU_OPTIONS.filter(
    (f) => (hideTools || [])?.indexOf(f.type) === -1
  );
  const { popupType } = useEditorContext();

  const canUndo = editor.history.undos.length > 0;
  const canRedo = editor.history.redos.length > 0;
  
  const [toolTip ,setToolTip] = useState(false)
  const [data, setData]= useState(null)

  useEffect(()=>{
    if(data){
      setToolTip(false)
      setData(null)
    }
  },[data])

  useEffect(() => {
    if (popper) {
      setPopper(null);
    }
  }, [editor.selection]);
  
  const handleClick = (type) => (e) => {
    if(type === "page-settings"){
      setToolTip(true);
    }
    setPopper(type);
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setPopper(null);
    setFullScreen(false);
  };

  const toggleFullscreen = () => {
    setFullScreen(!fullScreen);
  };

  const onSearch = (e) => {
    setSearch(e?.target?.value || "");
  };

  const closePopper = (data) => {
    setData(data)
    setToolTip("false");
  };
  
  return (
    <>
      <Box component={"div"} className="mini-tool-wrpr-ei" sx={classes.root}>
        {UPDATED_MENU_OPTIONS.map(({ type, label, icon: Icon }) => {
          const isDisabled = popupType === type || type === 'undo' ? !canUndo : type === 'redo' ? !canRedo : false ; // for textFormat type

          return (
            <Tooltip arrow key={`mini_tool_btn_${type}`} title={label} disableHoverListener={toolTip}>
              <IconButton
                className={`${type === popper ? "active" : ""} ${((type === 'undo' && !canUndo) || (type === 'redo' && !canRedo)) ? "disabled" : ""}`}
                onClick={handleClick(type)}
                disabled={isDisabled}
              >
                {type === "page-settings" ? (
                  <PageSettingsButton
                    from="miniToolBar"
                    icoBtnType={"mini"}
                    customProps={customProps}
                    editor={editor}
                    closePopper={closePopper}
                    setToolTip={setToolTip}
                  />
                ) : (
                <Icon from="miniToolBar" icoBtnType={"mini"}  customProps={customProps} editor={editor} />)}
              </IconButton>
            </Tooltip>
          );
        })}
      </Box>
      <DialogComp
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        placement="top"
        sx={popupStyles.popupWrapper}
        className={`tools-drawer ${fullScreen ? "fullscreen" : ""}`}
        fullScreen={fullScreen}
      >
        {PopupComponent ? (
          <Paper className="papper-wrpr" style={{ borderRadius: "10px" }}>
            <PopperHeader
              title={POPUP_TITLE[popper]}
              classes={popupStyles}
              onClose={onClose}
              needFullscreen={FULLSCREEN_POPUP[popper]}
              fullScreen={fullScreen}
              toggleFullscreen={toggleFullscreen}
              search={search}
              onSearch={onSearch}
            />
            <PopupComponent
              classes={popupStyles}
              editor={editor}
              customProps={customProps}
              fullScreen={fullScreen}
              setPopper={setPopper}
              onClose={onClose}
              search={search}
              onSearch={onSearch}
            />
          </Paper>
        ) : null}
      </DialogComp>
    </>
  );
};

export default MiniToolbar;
