/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Transforms, Path, Node } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import { IconButton, Tooltip, Grid as GridContainer } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { insertGrid } from "../../utils/grid";
import { GridAddGridIcon, GridSettingsIcon } from "../../common/iconslist";
import GridPopup from "./GridPopup";
import SectionPopup from "./SectionPopup";
import { gridItem } from "../../utils/gridItem";
import { useEditorContext, useEditorSelection } from "../../hooks/useMouseMove";
import { getTRBLBreakPoints, getBreakPointsValue } from "../../helper/theme";
import useWindowResize from "../../hooks/useWindowResize";

const GridSettingsPoupComp = {
  section: SectionPopup,
  grid: GridPopup,
};

const GridToolBar = ({
  selected,
  showTool,
  onSettings,
  onAddGridItem,
  onAddSection,
  onMoveSection,
  path,
  isSectionFullWidth,
}) => {
  return selected && !showTool ? (
    <div
      className="grid-container-toolbar"
      contentEditable={false}
      style={isSectionFullWidth ? { right: "4px", top: "4px" } : {}}
    >
      <Tooltip title="Grid Settings" arrow>
        <IconButton onClick={onSettings}>
          <GridSettingsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Grid Item" arrow>
        <IconButton onClick={onAddGridItem}>
          <GridAddGridIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Duplicate" arrow>
        <IconButton onClick={onAddSection()}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      {path.length === 2 ? (
        <>
          <Tooltip title="Move Up" arrow>
            <IconButton onClick={onMoveSection("up")}>
              <ArrowUpwardIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Move Down" arrow>
            <IconButton onClick={onMoveSection("down")}>
              <ArrowDownwardIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
    </div>
  ) : null;
};

const getParentEl = (editor, path) => {
  try {
    if (path?.length) {
      return Node.parent(editor, path);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const Grid = (props) => {
  const { attributes, children, element, customProps } = props;
  const { readOnly } = customProps;
  const [openSetttings, setOpenSettings] = useState(false);
  const {
    id,
    grid,
    bannerSpacing,
    bgColor,
    alignment,
    backgroundImage,
    fgColor,
    borderWidth,
    borderColor,
    borderStyle,
    borderRadius,
    flexWrap,
    xsHidden,
  } = element;
  const { vertical, horizantal, flexDirection } = alignment || {};
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const { hoverPath } = useEditorContext();
  const selected = hoverPath === path.join(",");
  const [showTool] = useEditorSelection(editor);
  const [size] = useWindowResize();

  const parentElement = getParentEl(editor, path);
  const { sectionGridSize } = parentElement || {};
  const isSectionFullWidth =
    sectionGridSize && sectionGridSize[size?.device] >= 12;

  const onAddGridItem = () => {
    const currentPath = editor.selection?.anchor?.path;
    const ancestorsPath = Path.ancestors(currentPath, { reverse: true });
    const insertPath = ancestorsPath[1];
    if (insertPath) {
      insertPath[insertPath.length - 1] = element.children.length;
      const lp = ReactEditor.findPath(editor, element);
      const lastElement = { ...element.children[element.children.length - 1] };
      Transforms.insertNodes(
        editor,
        gridItem({
          ...lastElement,
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ],
        }),
        {
          at: [...lp, children.length],
        }
      );
      // new line
      Transforms.insertNodes(
        editor,
        [{ type: "paragraph", children: [{ text: "" }] }],
        {
          at: [editor.children.length],
        }
      );
    }
  };

  const onSettings = () => {
    setOpenSettings("grid");
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const onDelete = () => {
    try {
      if (path) {
        Transforms.removeNodes(editor, { at: path });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onAddSection = () => () => {
    try {
      const duplicateGrid = JSON.parse(JSON.stringify(element));
      insertGrid(editor, duplicateGrid, [path[0] + 1, 0]);
    } catch (err) {
      console.log(err);
    }
  };

  const onMoveSection = (direction) => (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      if (direction) {
        let moveTo = direction === "up" ? path[0] - 1 : path[0] + 1;
        if (moveTo < 0) {
          moveTo = 0;
        } else if (moveTo > editor.children.length - 1) {
          moveTo = editor.children.length - 1;
        }
        Transforms.moveNodes(editor, { at: [path[0]], to: [moveTo] });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onNewLine = (direction) => () => {
    try {
      if (direction) {
        const path = ReactEditor.findPath(editor, element);
        let moveTo = direction === "up" ? path : Path.next(path);
        let lastLine = false;
        if (moveTo < 0) {
          moveTo = 0;
        } else if (moveTo > editor.children.length - 1) {
          moveTo = editor.children.length;
          lastLine = true;
        }
        Transforms.insertNodes(
          editor,
          { type: "paragraph", children: [{ text: "" }] },
          { at: [...moveTo], select: true }
        );
        if (lastLine) {
          Transforms.move(editor, { at: [moveTo] });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const PoupComp = GridSettingsPoupComp[openSetttings];

  const NewLineButtons = () => {
    return !readOnly && selected && path.length === 2 && !showTool ? (
      <>
        <div contentEditable={false} className="element-selector-ctrl tc">
          <Tooltip title="Add Space above" arrow>
            <IconButton onClick={onNewLine("up")}>
              <KeyboardReturnIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div contentEditable={false} className="element-selector-ctrl bc">
          <Tooltip title="Add Space below" arrow>
            <IconButton onClick={onNewLine("down")}>
              <KeyboardReturnIcon />
            </IconButton>
          </Tooltip>
        </div>
      </>
    ) : null;
  };

  const sectionId = id ? { id } : {};

  const bgImage =
    backgroundImage && backgroundImage !== "none"
      ? {
          backgroundImage: `url(${backgroundImage})`,
        }
      : {};

  const { topLeft, topRight, bottomLeft, bottomRight } =
    getBreakPointsValue(borderRadius, size?.device) || {};

  return (
    <GridContainer
      container
      className={`grid-container ${grid} has-hover element-root dpath`}
      {...attributes}
      {...sectionId}
      sx={{
        display: {
          lg: "flex",
          xs: xsHidden ? "none" : "flex",
        },
        background: bgColor,
        alignContent: vertical,
        ...bgImage,
        borderColor: borderColor || "transparent",
        borderWidth: borderWidth || "1px",
        borderRadius: `${topLeft}px ${topRight}px ${bottomRight}px ${bottomLeft}px`,
        borderStyle: borderStyle || "solid",
        height: "auto",
      }}
      data-path={path.join(",")}
    >
      {fgColor && (
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            width: "100%",
            height: "100%",
            background: fgColor,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        />
      )}
      {!readOnly && (
        <div
          className={`element-selector ${selected ? "selected" : ""}`}
          contentEditable={false}
        >
          <div className="element-selector-dots tl"> </div>
          <div className="element-selector-dots tr"> </div>
          <div className="element-selector-dots bl"> </div>
          <div className="element-selector-dots br"> </div>

          <GridToolBar
            selected={selected}
            showTool={showTool}
            onSettings={onSettings}
            onAddGridItem={onAddGridItem}
            onAddSection={onAddSection}
            onMoveSection={onMoveSection}
            path={path}
            isSectionFullWidth={isSectionFullWidth}
          />
        </div>
      )}
      {openSetttings ? (
        <PoupComp
          element={element}
          onSave={onSave}
          onClose={onClose}
          onDelete={onDelete}
          customProps={customProps}
        />
      ) : null}
      <GridContainer
        container
        className="grid-c-wrpr"
        sx={{
          display: "flex",
          padding: {
            ...getTRBLBreakPoints(bannerSpacing),
          },
          alignItems: vertical || "start",
          justifyContent: horizantal || "start",
          flexDirection: flexDirection || "row",
          width: "100%",
          flexWrap: {
            ...getBreakPointsValue(flexWrap || "wrap"),
          },
          height: "auto",
        }}
        data-path={path.join(",")}
      >
        {children}
      </GridContainer>
      <NewLineButtons />
    </GridContainer>
  );
};

export default Grid;
